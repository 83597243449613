import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import * as apiService from '../api';
import cookie from 'react-cookies';
import {navigate} from 'gatsby';
import * as trackAction from '../Application/Shared/TrackActionService';
import BulkUploadComponent from '../Application/Shared/BulkUpload/BulkUpload';

export const BulkUpload = () => {
  const [fileData, setFileData] = useState(null);
  const [failedAPICount, setFailedAPICount] = useState(0);
  const [studentSubcriptions, setStudentSubcriptions] = useState([]);
  const [registeredCoursesLen, setRegisteredCoursesLen] = useState(null);
  const [apiStatusData, setapiStatusData] = useState({});
  const [tempArray, setTempArray] = useState([]);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [patchApiCall, setPatchAPiCall] = useState(false);
  const [deleteCoursesApiCall, setDeleteCoursesApiCall] = useState(false);
  const [cancelSubscriptionApiCall, setCancelSubscriptionApiCall] =
  useState(false);
  const [freshSalesApiCall, setFreshSalesApiCall] =
    useState(false);
  const [studentDetailsApi, setStudentDetailsApi] = useState(false);
  const [getSubscriptionsApiCall, setGetSubscriptionsApiCall] = useState(false);

  const cancelSubscriptionRequestObj = {};
  cancelSubscriptionRequestObj['cancellation_reason'] =
  'Deleted by CS Agent';

  useEffect(()=>{
    if (Object.keys(apiStatusData).length > 0) {
      const tempVar = tempArray;
      const filteredData = tempVar.filter((item)=>
        item.leadId===apiStatusData?.leadId);

      // if data leadId is in tempArray
      if (filteredData.length!==0) {
        filteredData[0]?.apiStatus?.push(apiStatusData?.apiStatus[0]);
        tempVar.map((item)=>{
          if (item?.leadId === apiStatusData?.leadId) {
            item=filteredData;
          }
        });
        setTempArray(tempVar);
      } else {
        setTempArray([...tempArray, apiStatusData]);
      }
    }
  }, [apiStatusData]);

  useEffect(()=>{
    const loggedInEmail=cookie.load('agent_email');
    if (!loggedInEmail) {
      navigate('/gdpr-agent/login');
    }
  }, []);

  /**
 *
 *
 * get student subscriptions
 * @param {*} userData
 */
  const getAllSubscriptionsForStudent = (userData) => {
    new apiService.default.CsAgentSubscriptions(userData.userId).
        getAllSubcriptions()
        .then((res) => {
          setStudentSubcriptions([...studentSubcriptions, res.data.data]);
          cancelSubscription(userData, res.data.data.id);
          setGetSubscriptionsApiCall(true);
        })
        .catch((err) => {
          setStudentSubcriptions([...studentSubcriptions,
            {id: userData.userId, status: 'failed'}]);
          setapiStatusData({
            'leadId': userData.userId,
            'phone Number': userData.userInfo['Phone No'],
            'Email': userData.userInfo['Email'],
            'apiStatus': [{'api': 'getSubscription',
              'status': 'failed'}]});
          setGetSubscriptionsApiCall(true);
          setCancelSubscriptionApiCall(true);
        });
  };

  /**
 *
 *
 * get registered courses
 * @param {*} studentId
 */
  const getRegisteredCourses = (studentId) =>{
    const studentsInfo=[];
    new apiService.default.Registrations(studentId).list()
        .then((res) => (res.data)).then((res) => {
          studentsInfo = res;
          studentsInfo.data.map((studentData)=>{
            studentsInfo.included.map((studentIncludedData)=>{
              studentData.relationships?.modules?.data?.
                  map((moduleData)=>{
                    if (moduleData.id === studentIncludedData.id) {
                      moduleData['includedData']=studentIncludedData;
                    }
                  });
            });
          });
          setStudentRegistrations(studentsInfo.data);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  /**
 *
 *
 * get student details
 */
  const getStudentDetails = () =>{
    setTempArray([]);
    let responseCount=0;
    for (let i=0; i<=fileData.length; i++) {
      if (fileData.length!==0) {
        new apiService.default.
            CsAgentStudentDetail(fileData[i]).studentDetails()
            .then((res) => {
              responseCount++;
              if (responseCount===fileData.length-1) {
                setStudentDetailsApi(true);
              }
              const userData ={
                'userInfo': {
                  'StudentId': res?.data?.data?.id,
                  'FirstName': res?.data?.data?.attributes?.name?.first_name,
                  'LastName': res?.data?.data?.attributes.name?.last_name,
                  'Email': res?.data?.data?.attributes?.email,
                  'Phone No': res?.data?.data?.attributes?.phone,
                  'Country': res?.data?.data?.attributes?.address?.country,
                  'Language': res?.data?.data?.attributes?.language_id,
                  'Age': res?.data?.data?.attributes?.profile?.age,
                  'Gender': res?.data?.data?.attributes?.profile?.gender,
                  'Goals': res?.data?.data?.attributes?.profile?.goals,
                },
                'userId': res?.data?.data?.id,
                'timeZone': res?.data?.data?.attributes?.timezone,
              };
              patchAPICall(userData);
              getAllSubscriptionsForStudent(userData);
              deleteCourses(userData);
              deleteFreshSalesAPI(userData);
            })
            .catch((err) => {
              responseCount++;
              if (responseCount===fileData.length-1) {
                setStudentDetailsApi(true);
                setPatchAPiCall(true);
                setDeleteCoursesApiCall(true);
                setCancelSubscriptionApiCall(true);
                setGetSubscriptionsApiCall(true);
                setFreshSalesApiCall(true);
              }
              setapiStatusData({
                'leadId': fileData[i],
                'apiStatus': [{'api': 'studentDetails', 'status': 'failed'}]});
              console.error(err);
              setConfirmDelete(false);
            });
      }
    }
  };

  /**
 *
 *
 * delete student
 */
  const deleteStudent = () => {
    trackAction.ctaClickAction({
      'cta': 'Delete users',
      'action': 'StartGDPRUpload',
      'pageKind': 'GDPRLoginDashboard',
      'agentName': cookie.load('agent_name'),
    });
    // resetting values
    setFailedAPICount(0);
    setRegisteredCoursesLen(0);
    setConfirmDelete(true);

    // exportType=fileExportType;
    setapiStatusData({});
    setTempArray([]);
    setPatchAPiCall(false);
    setDeleteCoursesApiCall(false);
    setCancelSubscriptionApiCall(false);
    setFreshSalesApiCall(false);
    setStudentDetailsApi(false);
    setGetSubscriptionsApiCall(false);

    getStudentDetails();
  };

  /**
 *
 *
 * patch api call
 * @param {*} userData
 */
  const patchAPICall = (userData) =>{
    // data=[];
    if (userData) {
      const requestObject = {
        'data': {
          'type': 'students',
          'id': userData.userId,
          'attributes': {
            'email': `${userData.userId}@gmail.com`,
            'name': {
              'first_name': 'GDPR',
              'last_name': '.',
            },
            'phone': '9999999999',
          },
        },
      };
      new apiService.default.CsAgentStudentDetails(userData.userId).
          deleteStudent(requestObject)
          .then((res) => {
            if (res.status === 200) {
              setPatchAPiCall(true);
              setapiStatusData({
                'leadId': userData.userId,
                'phone Number': userData.userInfo['Phone No'],
                'Email': userData.userInfo['Email'],
                'apiStatus': [{'api': 'patchAPI', 'status': 'success'}]});
            }
          })
          .catch((err) => {
            setPatchAPiCall(true);
            setFailedAPICount(failedAPICount + 1);
            setapiStatusData({
              'leadId': userData.userId,
              'phone Number': userData.userInfo['Phone No'],
              'Email': userData.userInfo['Email'],
              'apiStatus': [{'api': 'patchAPi', 'status': 'failed'}]});
          });
    }
  };

  /**
 *
 *
 * cancel all subscriptions
 * @param {*} userData
 * @param {*} id
 */
  const cancelSubscription = (userData, id) =>{
    cancelSubscriptionRequestObj['lead_id'] = userData?.userId;
    new apiService.default.CsAgentSubscriptions(userData.userId,
        id)
        .cancelSubscription(cancelSubscriptionRequestObj)
        .then((res) => {
          setCancelSubscriptionApiCall(true);
          if (res.status === 200) {
            setTimeout(()=>{
              getAllSubscriptionsForStudent(userData);
            }, 14000);
            setapiStatusData({
              'leadId': userData.userId,
              'phone Number': userData.userInfo['Phone No'],
              'Email': userData.userInfo['Email'],
              'apiStatus': [{'api': 'CancelSubscription',
                'status': 'success'}]});
          }
        })
        .catch((err) => {
          setCancelSubscriptionApiCall(true);
          setFailedAPICount(failedAPICount + 1);
          setapiStatusData({
            'leadId': userData.userId,
            'phone Number': userData.userInfo['Phone No'],
            'Email': userData.userInfo['Email'],
            'apiStatus': [{'api': 'CancelSubscription', 'status': 'failed'}]});
        });
  };

  /**
 *
 *
 * delete registered courses
 * @param {*} userData
 */
  const deleteCourses = (userData) =>{
    new apiService.default.CsAgentStudentDetails(userData.userId).
        getStudentRegistrations()
        .then((res) => {
          setRegisteredCoursesLen(res.data.data.length);
          if (res.data.data.length !== 0) {
            const regCoursesLen = res.data.data.length;
            let coursesDeletedCount = 0;
            res.data.data.map((registeredCourse, index) =>{
              new apiService.default.CsAgentStudentDetails(registeredCourse.id).
                  deleteRegisteredCourse({
                    'data': {
                      'deferred_reason': 'GDPR',
                    }}).then((response)=>{
                    if (index === res.data.data.length-1) {
                      setDeleteCoursesApiCall(true);
                    }

                    if (response.status === 204) {
                      ++coursesDeletedCount;
                      if (coursesDeletedCount === regCoursesLen) {
                        if (registeredCoursesLen!==0 &&
                            (coursesDeletedCount === registeredCoursesLen)) {
                          setapiStatusData({
                            'leadId': userData.userId,
                            'phone Number': userData.userInfo['Phone No'],
                            'Email': userData.userInfo['Email'],
                            'apiStatus': [{'api': 'deleteCourse',
                              'status': 'success'}]});
                          getRegisteredCourses(userData.userId);
                        }
                      }
                    }
                  })
                  .catch((err)=>{
                    if (registeredCoursesLen!==0 &&
                        (coursesDeletedCount === registeredCoursesLen)) {
                      getRegisteredCourses(userData.userId);
                    }
                    setapiStatusData({
                      'leadId': userData.userId,
                      'phone Number': userData.userInfo['Phone No'],
                      'Email': userData.userInfo['Email'],
                      'apiStatus': [{'api': 'deleteCourse',
                        'status': 'failed'}]});
                    setFailedAPICount(failedAPICount + 1);
                    getRegisteredCourses(userData.userId);

                    if (index === res.data.data.length-1) {
                      setDeleteCoursesApiCall(true);
                    }
                  });
            });
          } else {
            setapiStatusData({
              'leadId': userData.userId,
              'phone Number': userData.userInfo['Phone No'],
              'Email': userData.userInfo['Email'],
              'apiStatus': [{'api': 'deleteCourse', 'status': 'success'}]});
            getRegisteredCourses(userData.userId);
            setDeleteCoursesApiCall(true);
          }
        })
        .catch((err) => {
          setDeleteCoursesApiCall(true);
          console.log(err);
          setFailedAPICount(failedAPICount + 1);
        });
  };

  /**
 *
 *
 * delete fresh sales
 * @param {*} userData
 */
  const deleteFreshSalesAPI = (userData) =>{
    new apiService.default.FreshSales(userData?.userInfo?.Email)
        .deleteFromFreshSales().then((res) => {
          setapiStatusData({
            'leadId': userData.userId,
            'phone Number': userData.userInfo['Phone No'],
            'Email': userData.userInfo['Email'],
            'apiStatus': [{'api': 'fresh sales', 'status': 'success'}]});
          setFreshSalesApiCall(true);
        })
        .catch((err)=>{
          setFailedAPICount(failedAPICount + 1);
          setapiStatusData({
            'leadId': userData.userId,
            'phone Number': userData.userInfo['Phone No'],
            'Email': userData.userInfo['Email'],
            'apiStatus': [{'api': 'fresh sales', 'status': 'error'}]});
          setFreshSalesApiCall(true);
        });
  };

  return (
    <div
      className={styles.bulkUploadWrapper}
    >
      <div className={styles.titleWrapper}>
        <p>GDPR Bulk upload</p>
      </div>
      <BulkUploadComponent
        inputId={'gdprFile'}
        buttonName={'Delete Users'}
        dataToExport={tempArray}
        buttonAction={deleteStudent}
        setFileData={setFileData}
        fileData={fileData}
        enableDownloadButton={freshSalesApiCall &&
          cancelSubscriptionApiCall &&
          deleteCoursesApiCall &&
          patchApiCall && studentDetailsApi &&
          getSubscriptionsApiCall}
        isLoading={confirmDelete && !(freshSalesApiCall &&
          cancelSubscriptionApiCall &&
          deleteCoursesApiCall && patchApiCall && studentDetailsApi&&
          getSubscriptionsApiCall)}
      />
    </div>
  );
};
