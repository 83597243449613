import React, {useEffect, useState} from 'react';
import {
  ColumnFlexWrapper,
  DisplayLayoutContainer,
} from '../Application/Shared2/Agent/styled-components';
import styles from './index.module.scss';
import loadingGif from '../../static/loading.gif';
import {navigate} from 'gatsby';
import * as authService from '../Application/Shared/CsAuthenticationService';

/**
 *
 *
 * @return {*}
 */
const Login = () => {
  const [authInfo, setAuthInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    authService.triggerAuthentication(setAuthInfo,
        setIsLoading, '/gdpr-agent/gdpr-upload');
  }, []);

  const handleLogin = () => {
    if (authInfo && authInfo.isSignedIn?.get()) {
      authInfo?.signOut();
    } else {
      authInfo?.signIn().then((res)=>{
        if (res) {
          navigate(`/gdpr-agent/gdpr-upload`);
        }
      }).catch(function(error) {
        console.log('login error', error);
      });
    }
  };
  return (
    <>
      <ColumnFlexWrapper>
        <DisplayLayoutContainer width30>
          <p>Login using ShawAcademy Account</p>
          <button
            onClick={handleLogin}
            className={isLoading?styles.disableLoginBtn:styles.loginBtn}
          >
            Login
          </button>
        </DisplayLayoutContainer>
      </ColumnFlexWrapper>
      {
        isLoading &&
        <img
          src={loadingGif}
          alt='loading'
          className={styles.loadingIcon}
        />
      }
    </>
  );
};

export default Login;

