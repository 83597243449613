import React from 'react';
import {Router} from '@reach/router';
import Login from './Login';
import {BulkUpload} from './BulkUpload';

/**
 *
 *
 * @return {*}
 */
function GdprAgent() {
  return (
    <>
      <Router basepath='/gdpr-agent'>
        <Login path="/login" />
        <BulkUpload path="/gdpr-upload" />
      </Router>
    </>
  );
}

export default GdprAgent;
